/* ./your-css-folder/styles.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  scroll-behavior: smooth;
  font-family: Pretendard, sans-serif;
}

.DialogWrapper {
  width: 500px;
}
.AlertDialogWrapper {
  max-width: 400px;
  margin: 0 auto;
}

@media (max-width: 1023px) {
  .DialogWrapper {
    width: 344px;
    min-width: 320px;
  }
  .AlertDialogWrapper {
    width: 100%;
  }
}
